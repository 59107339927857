<template>
  <div :class="[`v-step-warp-${direction}`, styleType]">
    <div class="v-step-progress-bg">
      <div class="v-step-progress-value" :style="progressStyle"></div>
    </div>
    <ul class="v-step-list">
      <li class="v-step-item"
        :style="horizontalItemWidth"
      >
        <div v-if="step.stepOneDate" class="v-step-item-label" @click="selectItem(1)">
          <div>
            <div class="info"><div class="title">项目信息</div><div>{{step.stepOneDate}}</div></div>
            <div class="end-date">承诺办结日期: {{step.stepOnePeriodDate? step.stepOnePeriodDate : '尚未完成'}}</div>
          </div>
          <div class="status"><i v-if="step.stepOnePeriodDate" class="el-icon-circle-check" style="color:#67C23A"></i><i v-else class="el-icon-edit"></i>完成项目登记</div>
          <div class="triangle"></div>
        </div>
        <div v-else class="v-step-item-label">
          <div>
            <div class="info"><div class="title">项目信息</div><div>尚未完成</div></div>
            <div class="end-date">承诺办结日期: {{step.stepOnePeriodDate? step.stepOnePeriodDate : '尚未完成'}}</div>
          </div>
          <div class="status"><i v-if="step.stepOnePeriodDate" class="el-icon-circle-check" style="color:#67C23A"></i><i v-else class="el-icon-edit"></i>完成项目登记</div>
          <div class="triangle"></div>
        </div>
        <div class="v-step-item-number" :style="itemNumberStyle(0)">
          {{ showItemNumber(0) }}
        </div>
      </li>
      <li class="v-step-item"
        :style="horizontalItemWidth"
      >
        <div v-if="step.stepTwoDate" class="v-step-item-label" @click="selectItem(2)">
          <div class="info"><div class="title">在线审图</div><div>{{step.stepTwoDate}}</div></div>
          <div class="status"><i v-if="step.stepTwoDate" class="el-icon-circle-check" style="color:#67C23A"></i><i v-else class="el-icon-edit"></i>四方进行图审</div>
          <div class="triangle"></div>
        </div>
        <div v-else class="v-step-item-label">
          <div class="info"><div class="title">在线审图</div><div>尚未完成</div></div>
          <div class="status"><i v-if="step.stepTwoDate" class="el-icon-circle-check" style="color:#67C23A"></i><i v-else class="el-icon-edit"></i>四方进行图审</div>
          <div class="triangle"></div>
        </div>
        <div class="v-step-item-number" :style="itemNumberStyle(1)">
          {{ showItemNumber(1) }}
        </div>
      </li>
      <li class="v-step-item"
        :style="horizontalItemWidth"
      >
        <div v-if="step.stepThreeDate" class="v-step-item-label" @click="selectItem(3)">
          <div class="info"><div class="title">专家审核</div><div>{{step.stepThreeDate}}</div></div>
          <div class="status"><i v-if="step.stepThreeDate" class="el-icon-circle-check" style="color:#67C23A"></i><i v-else class="el-icon-edit"></i>技术部评审图审意见</div>
          <div class="triangle"></div>
        </div>
        <div v-else class="v-step-item-label">
          <div class="info"><div class="title">专家审核</div><div>尚未完成</div></div>
          <div class="status"><i v-if="step.stepThreeDate" class="el-icon-circle-check" style="color:#67C23A"></i><i v-else class="el-icon-edit"></i>技术部评审图审意见</div>
          <div class="triangle"></div>
        </div>
        <div class="v-step-item-number" :style="itemNumberStyle(2)">
          {{ showItemNumber(2) }}
        </div>
      </li>
      <li class="v-step-item"
        :style="horizontalItemWidth"
      >
        <div v-if="step.stepFourDate" class="v-step-item-label" @click="selectItem(4)">
          <div class="info"><div class="title">初审意见</div><div>{{step.stepFourDate}}</div></div>
          <div class="status"><i v-if="step.stepFourDate" class="el-icon-circle-check" style="color:#67C23A"></i><i v-else class="el-icon-edit"></i>生成初审意见</div>
          <div class="triangle"></div>
        </div>
        <div v-else class="v-step-item-label">
          <div class="info"><div class="title">初审意见</div><div>尚未完成</div></div>
          <div class="status"><i v-if="step.stepFourDate" class="el-icon-circle-check" style="color:#67C23A"></i><i v-else class="el-icon-edit"></i>生成初审意见</div>
          <div class="triangle"></div>
        </div>
        <div class="v-step-item-number" :style="itemNumberStyle(3)">
          {{ showItemNumber(3) }}
        </div>
      </li>
      <li class="v-step-item"
        :style="horizontalItemWidth"
      >
        <div v-if="step.stepFiftyDate" class="v-step-item-label" @click="selectItem(5)">
          <div class="info"><div class="title">图审报告</div><div>{{step.stepFiftyDate ? step.stepFiftyDate : '尚未完成'}}</div></div>
          <div class="status"><i v-if="step.stepFiftyDate" class="el-icon-circle-check" style="color:#67C23A"></i><i v-else class="el-icon-edit"></i>生成图审报告</div>
          <div class="triangle"></div>
        </div>
        <div v-else class="v-step-item-label">
          <div class="info"><div class="title">图审报告</div><div>尚未完成</div></div>
          <div class="status"><i v-if="step.stepFiftyDate" class="el-icon-circle-check" style="color:#67C23A"></i><i v-else class="el-icon-edit"></i>生成图审报告</div>
          <div class="triangle"></div>
        </div>
        <div class="v-step-item-number" :style="itemNumberStyle(4)">
          {{ showItemNumber(4) }}
        </div>
      </li>
      <li class="v-step-item"
        :style="horizontalItemWidth"
      >
        <div class="v-step-item-label" @click="selectItem(6)">
          <div class="info"><div class="title">项目归档</div><div>
            {{step.stepSixDate ? step.stepSixDate : '尚未完成'}}</div></div>
          <div class="status">
            <i v-if="step.stepSixDate" class="el-icon-circle-check" style="color:#67C23A"></i><i v-else class="el-icon-edit"></i>项目审结资料归档
          </div>
          <div class="triangle"></div>
        </div>
        <div class="v-step-item-number" :style="itemNumberStyle(5)">
          {{ showItemNumber(5) }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "vue-step",
  props: {
    nowStep: {
      type: Number,
      required: true,
    },
    stepList: {
      type: Object,
      required: true,
    },
    activeColor: {
      type: String,
      default: "#1fb11d",
    },
    styleType: {
      type: String,
      default: "style1",
      validator: (value) => {
        return ["style1", "style2"].indexOf(value) !== -1;
      },
    },
    direction: {
      type: String,
      default: "horizontal",
    },
  },
  computed: {
    step(){
      return this.stepList;
    },
    stepItemWidth() {
      return 100 / 6;
    },
    horizontalItemWidth() {
      return this.direction === "vertical"
        ? ""
        : { width: this.stepItemWidth + "%" };
    },
    progressStyle() {
      let oStyle = {
        "background-color": this.activeColor,
      };
      if (this.direction === "vertical") {
        oStyle.height = this.stepItemWidth * this.nowStep + "%";
      } else {
        oStyle.width = this.stepItemWidth * this.nowStep + "%";
      }
      return oStyle;
    },
  },
  methods: {
    selectItem(value) {
      this.$emit("callFunction", value); //将值传给父组件
		},
    itemNumberStyle(index) {
      let style = "";
      if (index < this.nowStep) {
        style = {
          "background-color": this.activeColor,
          color: "#fff",
        };
      }
      return style;
    },
    showItemNumber(index) {
      return this.styleType !== "style2" ? index + 1 : "";
    },
  },
};
</script>

<style scoped>
.v-step-warp-horizontal {
  position: relative;
  padding: 10px 0;
  z-index: 2;
}
.v-step-warp-horizontal .v-step-progress-bg {
  position: absolute;
  width: 100%;
  height: 4px;
  bottom: 22px;
  background-color: #ddd;
}
.v-step-warp-horizontal .v-step-progress-value {
  position: inherit;
  top: 0;
  left: 0;
  height: inherit;
}
.v-step-list {
  position: relative;
  display: -webkit-flex;
  display: flex;
  user-select: none;
}
.v-step-list,
.v-step-item {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.v-step-warp-horizontal .v-step-list {
  justify-content: space-around;
  text-align: center;
}
.v-step-item-label {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 180px;
  height: 60px;
  margin: 10px auto;
  padding: 10px;
  text-align: left;
  font-size: 12px;
  color: #666;
  background-color: aliceblue;
  cursor: pointer;
  border-radius: 4px;
}

.v-step-item-label .info {
    display: flex;
    justify-content: space-between;
}

.v-step-item-label .info .title {
    font-weight: bold;
    font-size: 14px;
}

.v-step-item-label .end-date {
   margin-top: 6px;
}

.v-step-item-number {
  width: 18px;
  height: 18px;
  line-height: 18px;
  font-size: 12px;
  border-radius: 50%;
  color: #666;
  background-color: #ddd;
}
.v-step-warp-horizontal .v-step-item-number {
  margin: 5px auto;
}
.v-step-warp-horizontal.style2 .v-step-progress-bg {
  height: 2px;
  bottom: 19px;
}
.v-step-warp-horizontal.style2 .v-step-item-number {
  width: 10px;
  height: 10px;
  line-height: 10px;
}
.triangle {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid aliceblue;
  position: absolute;
  left: 50%;
  bottom: -3px;
  transform: rotate(180deg) translate(6px, -50%);
}
</style>
